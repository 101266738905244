<template>
    <div class="access-list" v-if="!loading">
        <div class="block-list">
            <div class="top-block">
                <div class="return_page">
                    <VigikBreadCrumb />
                </div>
                <h1 class="title">
                    {{ $route.params.vigikName }}
                </h1>
                <p v-if="accesses.data.not_owned_count !== null">
                    <i class="fas fa-exclamation-triangle" />
                    {{
                        $t("vigik.access.alert", {
                            count: accesses.data.not_owned_count,
                        })
                    }}
                </p>
            </div>
            <div class="middle-block">
                <div class="access-array">
                    <table>
                        <thead>
                            <tr>
                                <th class="access">
                                    {{ $t("vigik.access.access") }}
                                </th>
                                <th class="residence">
                                    {{ $t("vigik.access.residence") }}
                                </th>
                                <th class="building">
                                    {{ $t("vigik.access.building") }}
                                </th>
                                <th class="cage">
                                    {{ $t("vigik.access.cage") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="accesses.data.list.length > 0">
                            <tr
                                v-for="(acces, index) in accesses.data.list"
                                :key="`access-table-${index}`"
                                @click="gotoAccess(acces.acces_id)"
                            >
                                <td class="access">
                                    {{ acces.acces }}
                                </td>
                                <td class="residence">
                                    {{ acces.residence }}
                                </td>
                                <td class="building">
                                    {{ acces.batiment }}
                                </td>
                                <td class="cage">
                                    {{ acces.cage }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else class="no-accesses" colspan="4">
                            <tr>
                                <td colspan="4">
                                    {{ $t("global.array.emptyList") }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="bottom-block">
                <pagination
                    v-show="pagesCount > 1"
                    :pagesCount="pagesCount"
                    :page="currentPage"
                    v-on:new-current-page="handleCurrentPage"
                >
                </pagination>
            </div>
        </div>
    </div>
    <div v-else class="loader">
        <Loader />
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
import { getAccessTypeAccess } from "@/services/intratone/accesstype"
import Pagination from "@/components/basic/Pagination"
import VigikBreadCrumb from "../../components/entities/VigikBreadCrumb.vue"
import Loader from "@/components/basic/Loader"

export default {
    name: "AccessList",
    mixins: [v1mixin],
    components: {
        Pagination,
        VigikBreadCrumb,
        Loader,
    },
    data() {
        return {
            accesses: [],
            pagesCount: 0,
            currentPage: 1,
            loading: true,
        }
    },
    mounted() {
        this.updatePath()
        this.getData()
    },
    methods: {
        async handleCurrentPage(value) {
            this.currentPage = value
            await this.getData()
        },
        getData() {
            this.loading = true
            getAccessTypeAccess({
                access_type_id: this.$route.params.id,
                page: this.currentPage,
            }).then((response) => {
                this.accesses = response
                this.pagesCount = response.data._pages
                this.loading = false
            })
        },
        updatePath() {
            this.$store.commit("breadCrumb/pushBreadCrumb", [
                {
                    name: this.$t("vigik.access.title", {
                        name: this.$route.params.vigikName,
                    }),
                    link: this.$route.path,
                    routeName: "AccessList",
                },
            ])
        },
        goToVigik() {
            this.$router.push({
                name: "Vigik3",
            })
        },
        gotoAccess(id) {
            this.goToV1View("data/acces/load/id=" + id)
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}
.access-list {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: auto;

    @media all and (max-width: 768px) {
        padding: 20px 0 0 20px;
    }
    @media all and (min-width: 768px) {
        padding: 2em 4em 1em 4em;
    }

    .block-list {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;

        .top-block {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            width: 100%;

            @media all and (max-width: 768px) {
                padding: 0 20px 0 0;
            }

            p {
                margin: 0;

                i {
                    color: $yellow-warning;
                }
            }
            .return_page {
                a {
                    color: $blue-darker;
                    cursor: pointer;
                    text-decoration: none;
                    transition: color 200ms;
                    font-family: "Avenir Heavy";
                    font-size: 12px;
                    i {
                        margin-right: 10px;
                        transition: margin 200ms;
                    }
                }
                a:hover {
                    color: $orange-neutral;
                    i {
                        margin-right: 15px;
                    }
                }
            }
            .title {
                font-family: $font_avenir_black;
                margin: 0;
                width: 100%;
                font-size: $veryBig !important;
            }
        }
        .middle-block {
            height: 100%;
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow-x: auto;
            margin: 0;

            .access-array {
                width: 100%;
                overflow-x: auto;
                min-width: max-content;

                @media all and (max-width: 768px) {
                    padding-right: 20px;
                }

                table {
                    width: 100%;
                    thead {
                        border: none;
                        color: $blue-neutral;
                        tr {
                            border-bottom: solid 1px $grey-neutral;
                            th {
                                min-width: max-content;
                                font-family: $font_avenir_heavy;
                                padding: 1em 1em 1em 0;
                                font-size: $normal;
                                white-space: nowrap;
                                &.access {
                                    width: 30%;
                                }

                                &.residence {
                                    width: 30%;
                                }

                                &.building {
                                    width: 20%;
                                }

                                &.cage {
                                    width: 20%;
                                }
                            }
                        }
                    }
                    tbody {
                        color: $blue-dark;
                        font-size: $small;
                        font-family: $font-avenir-roman;

                        cursor: pointer;

                        &.no-accesses {
                            tr {
                                border-bottom: 0px solid $grey-neutral;
                                &:hover {
                                    background-color: $white;
                                    cursor: default;
                                }
                                td {
                                    text-align: center;
                                    color: $pink;
                                    font-family: $font_avenir_heavy;
                                }
                            }
                        }

                        tr {
                            &.add {
                                font-size: $normal;
                                color: $orange-neutral;
                                cursor: pointer;
                            }
                            background-color: $white;
                            transition: background-color 200ms;

                            border-bottom: solid 1px $grey-neutral;
                            td {
                                padding: 1em 1em 1em 0;
                                min-width: max-content;
                                text-wrap: nowrap;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;

                                i {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                                svg {
                                    font-size: $normal;
                                    cursor: pointer;
                                }
                            }
                            &:hover {
                                background-color: $grey-light;
                            }
                        }
                        border: none !important;
                    }
                }
            }
        }
        .bottom-block {
            display: flex;
            justify-content: center;

            .btn-style {
                padding: 20px;
            }
        }
        .top-block {
            @media all and (max-width: 768px) {
                .title {
                    font-size: $big;
                }
            }
        }
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
